import "../../styles/globals.css";
import "../../styles/reusable.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AppContext from "../context/AppContext";
import { useAuth } from "../common/hooks/auth";
import en from "../../public/static/locales/en/common.json";
import ar from "../../public/static/locales/ar/common.json";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { appWithTranslation } from "next-i18next";
import UserContext from "../context/UserContext";
import * as ga from "../common/libs/googleanalytics";
import { hotjar } from "react-hotjar";

function MyApp({ Component, pageProps }) {
  const { getplans, user, siteSettings } = useAuth({
    middleware: "guest",
  });
  const router = useRouter();
  const { locale } = router;
  const trans = locale === "en" ? en : ar;
  const [plans, setPlans] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [capturePendingInvoice, setCapturePendingInvoice] = useState(false);
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [planType, setPlanType] = useState("");
  const [modalType, setModalType] = useState("");
  const [userData, setUserData] = useState(user ?? []);
  const [guestTextQuota, setGuestTextQuota] = useState("20");
  const [guestDocQuota, setGuestDocQuota] = useState("2");
  const [guestFileSize, setGuestFileSize] = useState("2");
  const [guestFileFormat, setGuestFileFormat] = useState("docx");
  const [guestWordsCount, setGuestWordsCount] = useState("2000");
  const [guestDocWordsCount, setGuestDocWordsCount] = useState("2000");
  const [charLimit, setCharLimit] = useState(
    user?.char_limit ?? guestWordsCount
  );
  const [hyperpayCheckout, setHyperpayCheckout] = useState("");

  const [termsConditions, setTermsConditions] = useState("");
  const [termsConditionsAR, setTermsConditionsAR] = useState("");

  const [privacyPolicyEn, setPrivacyPolicyEn] = useState(""); // Add the state for privacy policy
  const [privacyPolicyAr, setPrivacyPolicyAr] = useState(""); // Add the state for privacy policy

  const [topWebinarTextEn, setTopWebinarTextEn] = useState(""); // Add the state for privacy policy
  const [topWebinarTextAr, setTopWebinarTextAr] = useState(""); // Add the state for privacy policy

  const [topWebinarLinkEn, setTopWebinarLinkEn] = useState(""); // Add the state for privacy policy
  const [topWebinarLinkAr, setTopWebinarLinkAr] = useState(""); // Add the state for privacy policy

  const [allowedFormats, setAllowedFormats] = useState(
    user?.file_formats || guestFileFormat
  );

  const [maxDocSize, setMaxDocSize] = useState(
    user?.max_doc_size ?? guestFileSize
  );

  const [requestQuota, setRequestQuota] = useState(
    user?.request_quota ?? guestTextQuota
  );
  const [docRequestQuota, setDocRequestQuota] = useState(
    user?.doc_request_quota ?? guestDocQuota
  );
  const [trialRemainingDays, setTrialRemainingDays] = useState(
    user?.trial_remaining_days ?? ""
  );
  const [docWordsCount, setDocWordsCount] = useState(
    user?.doc_words_count ?? guestDocWordsCount
  );
  const [paymentGateway, setPaymentGateway] = useState("");
  const [plansEN, setPlansEN] = useState([]);
  const [plansAR, setPlansAR] = useState([]);
  const [selectedLocale, setSelectedLocale] = useState("");

  const plansdecode = () => {
    const plans_details_en = [];
    const plans_details_ar = [];
    plans.forEach(function (plan) {
      const featuresEnglish = [];
      const featuresArabic = [];
      plan?.features_en?.forEach((plan, index) => {
        featuresEnglish.push(plan[index + 1]);
      });
      plan?.features_ar?.forEach((plan, index) => {
        featuresArabic.push(plan[index + 1]);
      });
      const en_plan_details = {
        price: plan.price,
        name: plan.name_en,
        symbol: plan.symbol_en,
        price: plan.price_en || "0",
        features: featuresEnglish,
        button_text: plan.button_text_en,
        description: plan.description_en,
        text_under_button: plan.text_under_button_en,
        badge: { color: plan.badge_color_en, title: plan.badge_title_en },
      };
      const ar_plan_details = {
        name: plan.name_ar,
        price: plan.price_ar || "0",
        symbol: plan.symbol_ar,
        features: featuresArabic,
        button_text: plan.button_text_ar,
        description: plan.description_ar,
        text_under_button: plan.text_under_button_ar,
        badge: { color: plan.badge_color_ar, title: plan.badge_title_ar },
      };
      plans_details_en.push(en_plan_details);
      plans_details_ar.push(ar_plan_details);
    });
    setPlansEN(plans_details_en);
    setPlansAR(plans_details_ar);
  };
  const fetchplans = async () => {
    const resp = await getplans();
    if (resp.data.length > 0) {
      setPlans(resp.data);
    }
  };
  // get plans
  useEffect(() => {
    if (plans.length == 0) {
      fetchplans();
    } else {
      plansdecode();
    }
  }, [plans]);

  //Site Settings

  const getSiteSettings = async () => {
    const settings = await siteSettings();
    settings.data.forEach((setting) => {
      if (setting.setting_name == "guest_text_quota") {
        setGuestTextQuota(setting.setting_value);
      } else if (setting.setting_name == "guest_doc_quota") {
        setGuestDocQuota(setting.setting_value);
      } else if (setting.setting_name == "terms_conditions") {
        setTermsConditions(setting.setting_value);
      } else if (setting.setting_name == "terms_conditions_ar") {
        setTermsConditionsAR(setting.setting_value);
      } else if (setting.setting_name == "guest_allowed_format") {
        setGuestFileFormat(setting.setting_value);
      } else if (setting.setting_name == "guest_file_size") {
        setGuestFileSize(setting.setting_value);
      } else if (setting.setting_name == "guest_words_count") {
        setGuestWordsCount(setting.setting_value);
      } else if (setting.setting_name == "guest_doc_words_count") {
        setGuestDocWordsCount(setting.setting_value);
      } else if (setting.setting_name == "payment_gateway") {
        setPaymentGateway(setting.setting_value);
      } else if (setting.setting_name == "privacy_policy_en") {
        setPrivacyPolicyEn(setting.setting_value); // Set the privacy policy content
      } else if (setting.setting_name == "privacy_policy_ar") {
        setPrivacyPolicyAr(setting.setting_value); // Set the privacy policy content
      } else if (setting.setting_name == "webinar_text_en") {
        setTopWebinarTextEn(setting.setting_value); // Set the privacy policy content
      } else if (setting.setting_name == "webinar_text_ar") {
        setTopWebinarTextAr(setting.setting_value); // Set the privacy policy content
      } else if (setting.setting_name == "webinar_link_en") {
        setTopWebinarLinkEn(setting.setting_value); // Set the privacy policy content
      } else if (setting.setting_name == "webinar_link_ar") {
        setTopWebinarLinkAr(setting.setting_value); // Set the privacy policy content
      }
    });
  };

  useEffect(() => {
    getSiteSettings();
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      setCharLimit(user?.char_limit);
      setRequestQuota(user?.request_quota);
      setDocRequestQuota(user?.doc_request_quota);
      setMaxDocSize(user?.max_doc_size);
      setDocWordsCount(user?.doc_words_count);
      setAllowedFormats(user?.file_formats);
      setTrialRemainingDays(user?.trial_remaining_days);
    } else {
      setCharLimit(guestWordsCount);
      setRequestQuota(guestTextQuota);
      setDocRequestQuota(guestDocQuota);
      setMaxDocSize(guestFileSize);
      setDocWordsCount(guestDocWordsCount);
      setAllowedFormats(guestFileFormat);
      setTrialRemainingDays(trialRemainingDays);
    }
  }, [user, guestTextQuota]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    hotjar.initialize(
      process.env.NEXT_PUBLIC_HOTJAR_ID,
      process.env.NEXT_PUBLIC_HOTJAR_VERSION
    );
  }, []);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        t: trans,
        locale: locale,
        plans: plans,
        plansEN: plansEN,
        plansAR: plansAR,
        setPlans: setPlans,
        setSelectedLocale: setSelectedLocale,
        selectedLocale: selectedLocale,
        guestDocQuota: guestDocQuota,
        guestTextQuota: guestTextQuota,
        termsConditions: termsConditions,
        termsConditionsAR: termsConditionsAR,
        guestFileFormat: guestFileFormat,
        guestWordsCount: guestWordsCount,
        guestDocWordsCount: guestDocWordsCount,
        guestFileSize: guestFileSize,
        paymentGateway: paymentGateway,
        setHyperpayCheckout: setHyperpayCheckout,
        hyperpayCheckout: hyperpayCheckout,
        privacyPolicyEn: privacyPolicyEn,
        privacyPolicyAr: privacyPolicyAr,
        topWebinarTextEn: topWebinarTextEn,
        topWebinarTextAr: topWebinarTextAr,
        topWebinarLinkEn: topWebinarLinkEn,
        topWebinarLinkAr: topWebinarLinkAr,
      }}
    >
      <UserContext.Provider
        value={{
          maxDocSize,
          charLimit: charLimit,
          requestQuota: requestQuota,
          setDocRequestQuota: setDocRequestQuota,
          docRequestQuota: docRequestQuota,
          setDocWordsCount: setDocWordsCount,
          docWordsCount: docWordsCount,
          trialRemainingDays: trialRemainingDays,
          setShowModal: setShowModal,
          showModal: showModal,
          setModalType: setModalType,
          modalType: modalType,
          user: user,
          setStep: setStep,
          step: step,
          setExtraData: setExtraData,
          extraData: extraData,
          setCapturePendingInvoice: setCapturePendingInvoice,
          capturePendingInvoice: capturePendingInvoice,
          planType: planType,
          setPlanType: setPlanType,
          allowedFormats: allowedFormats,
          setUserData: setUserData,
          userData: userData,
        }}
      >
        <Component {...pageProps} />
      </UserContext.Provider>
    </AppContext.Provider>
  );
}

export default appWithTranslation(MyApp);
