export const PLAN_FREE = "free";
export const PLAN_REGULAR = "regular";
export const PREMIUM_PLAN_ID = 2;
export const FREE_PLAN_ID = 1;
export const ADMIN_ROLE_ID = 1;
export const SIGNUP_CREATE_ACCOUNT_STEP = 1;
export const SIGNUP_CHOOSE_PLAN_STEP = 2;
export const SIGNUP_ADD_BILLING_STEP = 3;
export const SIGNUP_SUCCESS_STEP = 4;

export const HOME_URL = "/";
export const USER_TRANSLATION_HISTORY_URL = "/user/translation-history";
export const USER_BASIC_INFO_URL = "/user/basic-info";
export const USER_BILLING_CARD_URL = "/user/payment-management";
export const USER_PASSWORD_AUTH_URL = "/user/auth-settings";
export const USER_MEMBERSHIP_MANAGEMENT = "/user/membership";

export const CHECKOUT_URL = "/checkout/detail";

export const TARJAMA_LINK = "https://tarjama.com/";
export const TARJAMA_LINK_AR = "https://tarjama.com/ar";

export const AMT_LINK = "https://translate.tarjama.com/";
export const AMTLINK_AR = "https://translate.tarjama.com//ar";


export const T_PORTAL_LINK = "https://tportal.tarjama.com/";
export const T_PORTAL_LINK_AR = "https://tportal.tarjama.com/login?lang=ar";

export const T_WEBINAR_LINK = "https://app.livestorm.co/tarjama-fz-llc/gpt-5-impact-on-your-job-redefining-your-role-in-the-future?type=detailed";
export const T_WEBINAR_LINK_AR = "https://app.livestorm.co/tarjama-fz-llc/gpt-5-impact-on-your-job-redefining-your-role-in-the-future?type=detailed";

export const ABOUT_AMT = "https://www.tarjama.com/amt/";
export const ABOUT_AMT_AR = "https://www.tarjama.com/ar/amt-ar/";

export const ABOUT_US_LINK = "https://www.tarjama.com/company/";
export const ABOUT_US_LINK_AR = "https://www.tarjama.com/ar/%d9%86%d8%a8%d8%b0%d8%a9-%d8%b9%d9%86%d8%a7/";

export const CONTACT_US_LINK = "https://www.tarjama.com/contact-us/";
export const CONTACT_US_LINK_AR = "https://www.tarjama.com/ar/%d8%a7%d8%a8%d9%82%d9%89-%d8%b9%d9%84%d9%89-%d8%aa%d9%88%d8%a7%d8%b5%d9%84/";

export const ENTERPRISE_LINK = "https://www.tarjama.com/for-enterprise/";
export const ENTERPRISE_LINK_AR = "https://www.tarjama.com/ar/%d9%84%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa/";

export const TRANSLATOR_LINK = "https://www.tarjama.com/for-translators/";
export const TRANSLATOR_LINK_AR = "https://www.tarjama.com/ar/%d9%84%d9%84%d9%85%d8%aa%d8%b1%d8%ac%d9%85%d9%8a%d9%86/";

export const OUR_TECHNOLOGY_LINK = "https://www.tarjama.com/our-technologies/";
export const OUR_TECHNOLOGY_LINK_AR = "https://www.tarjama.com/ar/%d8%aa%d9%82%d9%86%d9%8a%d8%a7%d8%aa%d9%86%d8%a7/";

export const CAREERS_LINK = "https://www.tarjama.com/careers/";
export const CAREERS_LINK_AR = "https://www.tarjama.com/ar/%d9%88%d8%b8%d8%a7%d8%a6%d9%81-%d9%88%d9%85%d9%87%d9%86/";

export const BLOG = "https://www.tarjama.com/blog/";
export const BLOG_AR = "https://www.tarjama.com/ar/%d8%a7%d9%84%d9%85%d8%af%d9%88%d9%86%d8%a9/";

export const TARJAMA_HELP_LINK = "https://www.tarjama.com/help/";
export const TARJAMA_HELP_LINK_AR = "https://www.tarjama.com/ar/%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d9%85%d8%b3%d8%a7%d8%b9%d8%af%d8%a9/";

export const PRIVACY_NOTICE_LINK_AR = "https://tarjama.com/privacy-policy/";
export const PRIVACY_NOTICE_LINK = "https://tarjama.com/privacy-policy/";

export const TERMS_COND_LINK = "https://tarjama.com/terms/";
export const TERMS_COND_LINK_AR = "https://tarjama.com/terms/";

export const CASE_STUDIES_LINK = "https://tarjama.com/category/case-studies/";
export const CASE_STUDIES_LINK_AR = "https://tarjama.com/category/case-studies/";

export const LINKED_IN_LINK = "https://www.linkedin.com/company/tarjama";
export const INSTAGRAM_LINK = "https://www.instagram.com/tarjamainsider";
export const FACEBOOK_LINK = "https://www.facebook.com/tarjamamena";
export const TWITTER_LINK = "https://twitter.com/tarjamamena";
export const YOUTUBE_LINK = "https://www.youtube.com/channel/UCSd1XpXlb3UME1HVtiiSv1Q";

export const CHAR_LIMIT = "2000";
export const TRANSLATION_REQUEST_LIMIT = "10";
export const DOC_TRANSLATION_REQUEST_LIMIT = "2";
export const DOC_WORDS_COUNT = "1000";
export const MAX_DOC_SIZE = "2";
export const GUEST_USER_ALLOWED_FILE_FORMAT = "docx";
